import React from 'react'
import ab from "../Asset/ab.png"
import { CiLocationOn } from "react-icons/ci";
import { LuPhoneCall } from "react-icons/lu";
import { CiClock2 } from "react-icons/ci";
import { LiaHelicopterSolid } from "react-icons/lia";
const Contectus = () => {
  return (
    <div>
      <section
  className="page-title"
  style={{ backgroundImage: "url(https://img.freepik.com/free-photo/engineer-working-with-airplane_1098-12545.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1705104000&semt=ais)" }}
>
  <div className="auto-container">
    <div className="inner-container clearfix">
      <h1>Contact us</h1>
      <ul className="bread-crumb clearfix">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>Contact us</li>
      </ul>
    </div>
  </div>
</section>
<section className="contact-section">
  <div className="auto-container">
    <div className="sec-title text-center">
      <h2>Contact US</h2>
      <div className="separator">
      <LiaHelicopterSolid />
      </div>
    </div>
    <div className="contact-form">
      <form
        method="post"
        action="sendemail.php"
        id="contact-form"
        noValidate="novalidate"
      >
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12 form-group pull-right">
            <textarea name="message" placeholder="Message" defaultValue={""} />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <input
                  type="text"
                  name="username"
                  placeholder="Name"
                  required=""
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required=""
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone No"
                  required=""
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required=""
                />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <button type="submit" name="submit-form">
                  send Massage
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div className="contact-info">
      <div className="row clearfix">
        <div className="image-column col-lg-8 col-md-12 col-sm-12">
          <figure style={{paddingTop:"90px"}}>
            <img src={ab} alt="" />
          </figure>
        </div>
        <div className="info-column col-lg-4 col-md-12 col-sm-12">
          <h3>Contact Info</h3>
          <ul>
            <li>
              <span className='icon'>
            <CiLocationOn />
            </span>
              <p>
                <strong>Address:</strong>
                <br />
                SHOP NO 1 & 2, KANTI ATHARVA, CENTRAL PARK, MAKANE,
SAPHALE (W) 401102.PALGHAR, MAHARASHTRA, INDIA
<br />

              </p>
            </li>
            <li>
              <span className='icon'>
              <LuPhoneCall />
              </span>
            
              <p>
                <strong>Phone:</strong>+91-9324598211/9820447027
              </p>
              <p>
                <span>Email:</span>
                <a href="#">sales@aoml.org</a>
              </p>
            </li>
            <li>
              <span className='icon'>
              <CiClock2 />
              </span>
           

              <p>
                <strong>Workshop Timeing :</strong>
                <br /> 10:00am to 6:00pm Sunday Closed
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="contact-map-section">
  {/*Map Outer*/}
  <div className="map-outer">
    {/*Map Canvas*/}
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7518.639398340725!2d72.80164464126872!3d19.570799526952975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be701de6239250b%3A0xdacc35919b1b9da1!2sKanti%20Atharva!5e0!3m2!1sen!2sin!4v1706766093769!5m2!1sen!2sin"
      height={400}
      style={{ border: 0 }}
    />
  </div>
</section>

    </div>
  )
}

export default Contectus

import dec1 from "../Asset/documet/dec1.pdf"
import dec2 from "../Asset/documet/dec2.pdf"
import dec3 from "../Asset/documet/dec3.pdf"
import dec4 from "../Asset/documet/dec4.pdf"
import dec5 from "../Asset/documet/dec5.pdf"


const productjs = [
  {
    "id": 1,
    "category": "Pharmaceuticals & APLS",
    "ServiceName": "Aviation Certificate 1",

    "productImg": dec1
  },
  {
    "id": 2,
    "category": "Pharmaceuticals & APLS",
    "ServiceName": "Aviation Certificate 2",

    "productImg": dec1
  },
  {
    "id": 3,
    "category": "Pharmaceuticals & APLS",
    "ServiceName": "Aviation Certificate 3",

    "productImg": dec1},
    {
        "id": 3,
        "category": "Pharmaceuticals & APLS",
        "ServiceName": "Aviation Certificate 3",
    
        "productImg": dec4},
        


];

export default productjs;

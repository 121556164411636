import React, { useState } from 'react';
import { GrUserWorker } from "react-icons/gr";
import { GiHelicopter } from "react-icons/gi";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { FaTools } from "react-icons/fa";
import { GiAirplane } from "react-icons/gi";
import { BsTools } from "react-icons/bs";
const Aboutus = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const tabsData = [
    {
      id: 1,
      title: 'AOML Workshop',
      content1: 'Welcome to AOML Workshop, your trusted destination for Quality NDT &amp; wheel and brake services. Our skilled technicians are equipped to handle a wide range of maintenance and repair needs, ensuring the optimal performance and safety of your aircraft &amp; aircraft components. From routine inspections to complex wheel overhauls, we specialize in delivering precision and efficiency. At AOML Workshop, we prioritize quality NDT, utilizing Advance technology and industry best practices. Trust us to keep your helicopters and jets in top-notch condition, ensuring Quality NDT, Wheel and brake service &amp; Calibration Service. Elevate your expectations with AOML Workshop – where excellence takes flight.',
      content3:"Experience Quality NDT services at AOML Workshop. Our skilled technicians utilize state-of-the- art equipment to diagnose and fix issues efficiently. Whether it&#39;s routine NDT, Wheel Brake &amp; Calibration Service, trust us to keep your Aircraft in peak condition for optimal performance and safety. We prioritize quality, ensuring your aircraft meets industry standards. At AOML Workshop, we are dedicated to delivering reliable and timely NDT, Wheel Brake &amp; Calibration Service that exceed expectations.",
      content2:'The AOML Workshop is a collaborative space dedicated to the development and innovation of modron technology, fostering creativity and advancements in automated systems.'    },
    {
      id: 2,
      title: 'Talented workers',
      content1: 'Ensure the optimal performance of your aircraft with our expert helicopter repair services. Our skilled technicians specialize in comprehensive inspections, maintenance, and repairs to keep your helicopter in top condition. From engine overhauls to avionics upgrades, we provide a full range of services, ensuring safety and reliability. Trust us to deliver quality craftsmanship and prompt turnaround times for all your helicopter repair needs.',
      content3:"Elevate your jet&#39;s performance with our precision NDT services. Our team of experienced technicians is dedicated to providing top-notch maintenance and repairs for various NDT Methods. Whether it&#39;s troubleshooting issues or conducting thorough NDT inspections, we bring unparalleled expertise to the table. Count on us for efficient and reliable Services, ensuring your aircraft operates at peak efficiency. Enhance your flying experience with our commitment to excellence in our Services.",
      content2:"Exceptionally skilled and dedicated professionals, adept at delivering outstanding results and driving innovation in their respective fields."
    },
    {
      id: 3,
      title: 'Leading avition specialist',
      content1: 'As a leading avition specialist, we extend our expertise to Quality NDT services, ensuring precision and reliability in every aspect. Our skilled Engineers employ state-of-the-art diagnostics and NDT techniques to address both routine maintenance and complex issues. With a commitment to safety and efficiency, we guarantee Quality services that adhere to the highest industry standards. Trust us for comprehensive solutions that keep your Aircraft in optimal condition, ready to soar with confidence.',
      content3:"Experience unparalleled services from the industry's leading Excellent specialist. Our team of certified Engineers &amp; technicians brings a wealth of knowledge and skill to ensure your aircraft receives meticulous attention. Whether it's NDT, wheel &amp; brake and calibration service or routine maintenance, we deliver results that surpass expectations. We prioritize efficiency, safety, and client satisfaction, making us the go-to choose for Aircraft owners seeking excellence. Entrust your NDT, Calibration &amp; wheel and brake service needs to us, and enjoy the skies with the assurance of top-tier service.",
      content2:"A leading avition specialist excels in delivering top-notch avitionmotive expertise, offering comprehensive solutions and innovative services to meet the diverse needs of vehicle owners with precision and excellence."
    },
  ];


  return (
    <div>
      <section
  className="page-title"
  style={{ backgroundImage: "url(https://img.freepik.com/free-photo/engineer-working-with-airplane_1098-12545.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1705104000&semt=ais)" }}
>
  <div className="auto-container">
    <div className="inner-container clearfix">
      <h1>ABOUT US</h1>
      <ul className="bread-crumb clearfix">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>About Us</li>
      </ul>
    </div>
  </div>
</section>
<section className="about-us">
  <div className="auto-container">
    <div className="row clearfix">
      {/* Content Column */}
      <div className="content-column col-lg-6 col-md-12 col-sm-12">
        <h2>Welcome to Avitr</h2>
        <h4>Our Vision</h4>
        <div className="primary-text">
        To move with velocity to drive profitable growth and become an even better serving more customers & become partner of choice and value trust of our employees, customers, suppliers, vendors and stake holders
        </div>
        <div className="text">
         
Our commitment to excellence is evident in our unwavering adherence to the highest standards of integrity. At our core, we prioritize treating our team members with utmost respect and dignity. This unwavering dedication to ethical conduct not only defines us but also ensures a workplace environment where every individual is valued. Our principles shape the foundation of a culture that fosters mutual respect and teamwork. Through this commitment, we establish a strong framework for success, creating an atmosphere where integrity and the well-being of our people take precedence.
        </div>
       
      </div>
      {/* Image Column */}
      <div className="image-column col-lg-6 col-md-12 col-sm-12">
        <div className="image-box">
          <a href="about.html">
            <img src="https://img.freepik.com/free-photo/engineer-working-with-airplane_1098-12545.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1705104000&semt=ais" alt="" />
          </a>
        </div>
       
      </div>
      <div className="row clearfix">
          <div className="column col-lg-3 col-md-6 col-sm-12">
            
              <h4 style={{fontWeight:"500",color:"#36404b"}}>Customer First</h4>
            
            <p>
            Our conduct reflects the highest standard of integrity and we treat our people with respect and dignity.
            </p>
            
          </div>
          <div className="column col-lg-3 col-md-6 col-sm-12">
            
           
          <h4 style={{fontWeight:"500",color:"#36404b"}}>Constant Innovation & Quality</h4>
            
            <p>
            We always put our Clients and Customers First.
            </p>
            
          </div>
          <div className="column col-lg-3 col-md-6 col-sm-12">
            
            
          <h4 style={{fontWeight:"500",color:"#36404b"}}>Ownership</h4>
            
            <p>
            We are committed to constant innovation and exhibit continuous zeal for quality in everything we do.
            </p>
            
          </div>
          <div className="column col-lg-3 col-md-6 col-sm-12">
            
           
          <h4 style={{fontWeight:"500",color:"#36404b"}}>Teamwork</h4>
        
            <p>
            The strength of the team is each individual member. The strength of each member is the team.
            </p>
            
          </div>
        </div>
    </div>
  </div>
</section>
<section
  className="fun-fact-section"
  style={{ backgroundImage: "url(images/background/1.jpg)" }}
>
  <div className="auto-container">
    <div className="row clearfix">
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <GrUserWorker />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Total experts</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <GiHelicopter />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Service Done</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <HiOutlineEmojiHappy />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1226}>
            1226
          </span>
          <div className="counter-title">
            <h5>Happy Client</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <FaTools />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Total Service</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="why-us-section">
      <div className="auto-container">
        {/* Features Tab */}
        <div className="feature-tabs tabs-box">
          <div className="row clearfix">
            <div className="content-column pull-right col-lg-7 col-md-12 col-sm-12">
              {/*Tabs Container*/}
              <div className="tabs-content">
                {tabsData.map((tab) => (
                  <div
                    key={tab.id}
                    className={`tab ${activeTab === tab.id ? 'active-tab' : ''}`}
                  >
                    <div className="inner-box">
                      <span className="title">Why Choose Us?</span>
                      <h2>{tab.title}</h2>
                      <p>{tab.content1}</p>
                      <p>{tab.content3}</p>

                      
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="btn-column col-lg-5 col-md-12 col-sm-12">
              {/*Tab Btns*/}
              <ul className="tab-btns tab-buttons clearfix">
                {tabsData.map((tab) => (
                  <li
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`tab-btn ${activeTab === tab.id ? 'active-btn' : ''}`}
                  >
                    <div className="icon-box">
                    <BsTools className='iconnew' />
                    </div>
                    <h3>{tab.title}</h3>
                    <p>{tab.content2.substring(0, 50)}...</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    </div>
  )
}

export default Aboutus

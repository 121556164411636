import React from 'react'
import "../style/gallery.css"
const Gallery = () => {
  return (
    <div>
   <section
  className="page-title"
  style={{ backgroundImage: "url(https://img.freepik.com/free-photo/engineer-working-with-airplane_1098-12545.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1705104000&semt=ais)" }}
>
  <div className="auto-container">
    <div className="inner-container clearfix">
      <h1>News & Gallery</h1>
      <ul className="bread-crumb clearfix">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>News/Gallery</li>
      </ul>
    </div>
  </div>
</section>

      <div className="Gallery-Section-2">
  <div className="Gallery-Card">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="Tabs-container">
            <div className="react-reveal text-center" style={{ opacity: 1 }}>
              <button
                type="button"
                className="btn btn-outline-primary my-2 mx-3 text-capitalize ButtonG"
                style={{
                  animationFillMode: "both",
                  animationDuration: "1000ms",
                  animationDelay: "0ms",
                  animationIterationCount: 1,
                  opacity: 1,
                  animationName: "react-reveal-668034378012272-1"
                }}
              >
                all
              </button>
              <button
                type="button"
                className="btn btn-outline-primary my-2 mx-3 text-capitalize ButtonG"
                style={{
                  animationFillMode: "both",
                  animationDuration: "1189ms",
                  animationDelay: "0ms",
                  animationIterationCount: 1,
                  opacity: 1,
                  animationName: "react-reveal-668034378012272-1"
                }}
              >
                News
              </button>
              <button
                type="button"
                className="btn btn-outline-primary my-2 mx-3 text-capitalize ButtonG"
                style={{
                  animationFillMode: "both",
                  animationDuration: "1414ms",
                  animationDelay: "0ms",
                  animationIterationCount: 1,
                  opacity: 1,
                  animationName: "react-reveal-668034378012272-1"
                }}
              >
                Gallery
              </button>
             
            </div>
          </div>
          <div className="row">
            <div
              className="react-reveal col-lg-4 col-md-6 my-3"
              style={{
                animationFillMode: "both",
                animationDuration: "1000ms",
                animationDelay: "0ms",
                animationIterationCount: 1,
                opacity: 1,
                animationName: "react-reveal-668034378012272-2"
              }}
            >
              <div className="card bg-light text-center">
                <img src="https://media.istockphoto.com/id/1281334322/photo/aircraft-mechanic-checking-jet-engine-of-the-airplane.jpg?s=612x612&w=0&k=20&c=f2urq1N_Pn3Z4Bolw01-PtF4e2kai8gmnMfYxpJHi7s=" />
                <div className="Card-text">
                  <h2 />
                </div>
              </div>
            </div>
            <div
              className="react-reveal col-lg-4 col-md-6 my-3"
              style={{
                animationFillMode: "both",
                animationDuration: "1000ms",
                animationDelay: "0ms",
                animationIterationCount: 1,
                opacity: 1,
                animationName: "react-reveal-668034378012272-2"
              }}
            >
              <div className="card bg-light text-center">
                <img src="https://media.istockphoto.com/id/1281334322/photo/aircraft-mechanic-checking-jet-engine-of-the-airplane.jpg?s=612x612&w=0&k=20&c=f2urq1N_Pn3Z4Bolw01-PtF4e2kai8gmnMfYxpJHi7s=" />
                <div className="Card-text">
                  <h2 />
                </div>
              </div>
            </div>
            <div
              className="react-reveal col-lg-4 col-md-6 my-3"
              style={{
                animationFillMode: "both",
                animationDuration: "1000ms",
                animationDelay: "0ms",
                animationIterationCount: 1,
                opacity: 1,
                animationName: "react-reveal-668034378012272-2"
              }}
            >
              <div className="card bg-light text-center">
                <img src="https://media.istockphoto.com/id/1281334322/photo/aircraft-mechanic-checking-jet-engine-of-the-airplane.jpg?s=612x612&w=0&k=20&c=f2urq1N_Pn3Z4Bolw01-PtF4e2kai8gmnMfYxpJHi7s=" />
                <div className="Card-text">
                  <h2 />
                </div>
              </div>
            </div>
            <div
              className="react-reveal col-lg-4 col-md-6 my-3"
              style={{
                animationFillMode: "both",
                animationDuration: "1000ms",
                animationDelay: "0ms",
                animationIterationCount: 1,
                opacity: 1,
                animationName: "react-reveal-668034378012272-2"
              }}
            >
              <div className="card bg-light text-center">
                <img src="https://media.istockphoto.com/id/1281334322/photo/aircraft-mechanic-checking-jet-engine-of-the-airplane.jpg?s=612x612&w=0&k=20&c=f2urq1N_Pn3Z4Bolw01-PtF4e2kai8gmnMfYxpJHi7s=" />
                <div className="Card-text">
                  <h2 />
                </div>
              </div>
            </div>
            <div
              className="react-reveal col-lg-4 col-md-6 my-3"
              style={{
                animationFillMode: "both",
                animationDuration: "1000ms",
                animationDelay: "0ms",
                animationIterationCount: 1,
                opacity: 1,
                animationName: "react-reveal-668034378012272-2"
              }}
            >
              <div className="card bg-light text-center">
                <img src="https://media.istockphoto.com/id/1281334322/photo/aircraft-mechanic-checking-jet-engine-of-the-airplane.jpg?s=612x612&w=0&k=20&c=f2urq1N_Pn3Z4Bolw01-PtF4e2kai8gmnMfYxpJHi7s=" />
                <div className="Card-text">
                  <h2 />
                </div>
              </div>
            </div>
            <div
              className="react-reveal col-lg-4 col-md-6 my-3"
              style={{
                animationFillMode: "both",
                animationDuration: "1000ms",
                animationDelay: "0ms",
                animationIterationCount: 1,
                opacity: 1,
                animationName: "react-reveal-668034378012272-2"
              }}
            >
              <div className="card bg-light text-center">
                <img src="https://media.istockphoto.com/id/1281334322/photo/aircraft-mechanic-checking-jet-engine-of-the-airplane.jpg?s=612x612&w=0&k=20&c=f2urq1N_Pn3Z4Bolw01-PtF4e2kai8gmnMfYxpJHi7s=" />
                <div className="Card-text">
                  <h2 />
                </div>
              </div>
            </div>
           
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Gallery

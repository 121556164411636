import React from 'react'

import logo from "../Asset/reavitrwebsite/brandss/aalogo.png"
const Footer = () => {
  return (
    <div>
      <footer
  className="main-footer"
  style={{ backgroundImage: "url(images/background/4.jpg)" }}
>
  <div className="auto-container">
    {/*Widgets Section*/}
    <div className="widgets-section">
      <div className="row clearfix">
        {/*Footer Column*/}
        <div className="footer-column col-lg-3 col-md-6 col-sm-12">
          <div className="footer-widget about-widget">
            <div className="footer-logo">
              <figure>
                <a href="index.html">
                  <img src={logo} alt="" style={{width:"135px"}} />
                </a>
              </figure>
            </div>
            <div className="widget-content">
              <div className="text">
                How to pursue pleasure rationally thats encounter consequences
                that extremely painful. Nor again is there anyones who loves or
                pursues or ut desires obtains pain of itself, because.
              </div>
              <h4>Follow Us:</h4>
              <ul className="social-icon">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-google-plus-g" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-pinterest" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-dribbble" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*Footer Column*/}
        <div className="footer-column col-lg-3 col-md-6 col-sm-12">
          <div className="footer-widget services-widget">
            <h2 className="widget-title">Links</h2>
            <div className="widget-content">
              <ul className="services-list">
                <li>
                <a href="/">Home</a>
                </li>
                <li>
                <a href="/aboutus">About</a>
                </li>
                <li>
                <a href="/Service">Services</a>
                </li>
                

              </ul>
            </div>
          </div>
        </div>
        {/*Footer Column*/}
        <div className="footer-column col-lg-3 col-md-6 col-sm-12">
          {/*Footer Column*/}
          <div className="footer-widget gallery-widget">
            <h2 className="widget-title">Links</h2>
            {/*Footer Column*/}
            <div className="widget-content">
              <div className="outer clearfix">
              <ul className="services-list">
                
                <li>
                <a href="/Gallery">News</a>
                </li>
                <li>
                      <a href="/Contectus">Contact</a>
                    </li>
                

              </ul>
              </div>
            </div>
          </div>
        </div>
        {/*Footer Column*/}
        <div className="footer-column col-lg-3 col-md-6 col-sm-12">
          {/*Footer Column*/}
          <div className="footer-widget news-widget">
            <h2 className="widget-title">Location</h2>
            {/*Footer Column*/}
            <div style={{borderRadius:"25px"}}>
              <iframe style={{borderRadius:"25px"}}
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7518.639398340725!2d72.80164464126872!3d19.570799526952975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be701de6239250b%3A0xdacc35919b1b9da1!2sKanti%20Atharva!5e0!3m2!1sen!2sin!4v1706766093769!5m2!1sen!2sin"/>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*Footer Bottom*/}
  <div className="footer-bottom">
    <div className="auto-container">
      <div className="copyright-text">
        <p>
          Copyrights © 2023 All Rights Reserved. by{" "}
          <a href="#"> Avitr</a>
        </p>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer


import "../style/responsive.css"
import { GiPlaneWing } from "react-icons/gi";
import Banner from '../components/Banner'
import ab2 from "../Asset/ab2.png"
import { RiDoubleQuotesL } from "react-icons/ri";
// import { Collapse } from 'antd';
import { FaMinus } from "react-icons/fa6";
import logo from "../Asset/logo.png"
import { FiPlus } from "react-icons/fi";
import { GrUserWorker } from "react-icons/gr";
import { GiHelicopter } from "react-icons/gi";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { FaTools } from "react-icons/fa";
import servicedata from "../components/Servicedata"
import { BsTools } from "react-icons/bs";
import { GiBiplane } from "react-icons/gi";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaCarBattery } from "react-icons/fa6";
import "../style/theme.css"
import 'swiper/css';



import { LiaHelicopterSolid } from "react-icons/lia";
// import required modules
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';


import { Autoplay} from 'swiper/modules';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
import a from "../Asset/reavitrwebsite/brands/a.webp"
import b from "../Asset/reavitrwebsite/brands/b.webp"
import c from "../Asset/reavitrwebsite/brands/c.webp"
import d from "../Asset/reavitrwebsite/brands/d.webp"
import e from "../Asset/reavitrwebsite/brands/e.webp"
import f from "../Asset/reavitrwebsite/brands/f.webp"
import g from "../Asset/reavitrwebsite/brands/g.webp"
import h from "../Asset/reavitrwebsite/brands/h.webp"
import i from "../Asset/reavitrwebsite/brands/i.webp"
import j from "../Asset/reavitrwebsite/brands/j.webp"
import k from "../Asset/reavitrwebsite/brands/k.webp"
import l from "../Asset/reavitrwebsite/brands/l.webp"
import m from "../Asset/reavitrwebsite/brands/m.webp"
import n from "../Asset/reavitrwebsite/brands/n.webp"
import o from "../Asset/reavitrwebsite/brands/o.webp"
import p from "../Asset/reavitrwebsite/brands/p.webp"
import q from "../Asset/reavitrwebsite/brands/q.webp"
import r from "../Asset/reavitrwebsite/brands/r.webp"
import s from "../Asset/reavitrwebsite/brands/s.webp"
import t from "../Asset/reavitrwebsite/brands/t.webp"
import u from "../Asset/reavitrwebsite/brands/u.webp"
import v from "../Asset/reavitrwebsite/brands/v.webp"
import w from "../Asset/reavitrwebsite/brands/w.webp"

const Home = () => {

  const [items, setItems] = useState([
    {
      title: "Shuld i consider using synthetic jet oil?",
      content: "We know that sometimes it’s difficult to get to the phone if you are in the middle of something and you don’t want to miss that important call that could be the start of an exciting new business opportunity.",
      isActive: false
    },
    {
      title: "What parts should be replaced at what intervals?",
      content: "We know that sometimes it’s difficult to get to the phone if you are in the middle of something and you don’t want to miss that important call that could be the start of an exciting new business opportunity.",
      isActive: true
    },
    {
      title: "How do I keep track of routine maintenance?",
      content: "We know that sometimes it’s difficult to get to the phone if you are in the middle of something and you don’t want to miss that important call that could be the start of an exciting new business opportunity.",
      isActive: false
    },
    {
      title: "What is the process by Engine maintenance?",
      content: "We know that sometimes it’s difficult to get to the phone if you are in the middle of something and you don’t want to miss that important call that could be the start of an exciting new business opportunity.",
      isActive: false
    }
  ]);
  
  const handleItemClick = (index) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, isActive: !item.isActive };
      } else {
        return { ...item, isActive: false };
      }
    });
    setItems(newItems);
  };

  return (
    <div>
        <Banner/>
        <section
  className="call-to-action"
  style={{ backgroundImage: "url(https://expert-themes.com/html/motor-expert/images/background/5.jpg)" }}
>
  <div className="inner-container clearfix">
    <div className="title-box">
      <h3>
        If You Need Any <span>technical solution ...</span> We Are Available For
        You We Manage Every Kind of Business , Join Now!!
      </h3>
    </div>
    <div className="btn-box">
      <a href="#" className="theme-btn btn-style-one">
        Make An Appointment
      </a>
    </div>
  </div>
</section>
<section className="services-section">
  <div className="auto-container">
    <div className="services-carousel owl-carousel owl-theme owl-rtl owl-loaded owl-drag">
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      {/* Service Block */}
      <div className="owl-stage-outer">
        <div
          className="owl-stage"
          style={{
           
          }}
        >
         <div className="row">
         {servicedata.map((ele) => {
                              const {service,slug,img} = ele;
                              return (
            <div className="col-md-4">
            <div className="owl-item" style={{ width: 370, marginLeft: 30 }}>
            <div className="service-block">
              <div className="inner-box">
                <div className="image-box">
                  <figure>
                    <img src={img} alt=""  style={{height:"380px"}}/>
                  </figure>
                  <div className="title">
                    <h4>{service}</h4>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="title-box">
                    <span className="icon flaticon-electrical" />
                    <h4>
                      <a href="service-detail.html">{service}</a>
                    </h4>
                  </div>
                  <p>
                    We should point out that maintaining your vehicle with an
                    occasional visual inspectionfluid level.
                  </p>
                  <a href={slug} className="read-more">
                    Read More <i className="fa fa-angle-double-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
            </div> );
                            })}
           
            {/* {choutelajson.map((ele) => {
                              const { title, slug,img } = ele;
                              return (
                                <div className="col-lg-4 col-md-4 mt-5">
                                <div
                                  className="featured-item services  text-center"
                                  data-bg-color="rgba(253,91,3,0.070)"
                                  style={{ backgroundColor: "rgba(253, 91, 3, 0.07)" }}
                                >
                                  <img src={img} width={325} height={200} />
                                  <div className="featured-title">
                                    <h5 style={{ textAlign: "center", marginTop: 10 }}>{title}</h5>
                                  </div>
                                  <div className="featured-desc">
                                    <a className="btn btn-2 gst" >
                                      {" "}
                                      <span className="btn-icon btn-arrow" />
                                     
                                      <p className="btn-text">  <Link to={slug}>Read More</Link></p>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              );
                            })} */}
         </div>
          
          
         
        </div>
      </div>
     
      
    </div>
  </div>
</section>

<section
  className="services-section-two"
  style={{ backgroundImage: "url(https://expert-themes.com/html/motor-expert/images/background/pattern-2.png)" }}
>
  <div className="auto-container">
    <div className="sec-title text-center">
      <h2>Our Best Service</h2>
      <div className="separator">
          <span className='icon'>
          <LiaHelicopterSolid />
          </span>
      </div>
    </div>
    <Swiper
        slidesPerView={6}
        spaceBetween={30}
        freeMode={true}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
          640: {
              slidesPerView: 2,
              spaceBetween: 20,
          },
          768: {
              slidesPerView: 2,
              spaceBetween: 40,
          },
          1024: {
              slidesPerView: 6,
              spaceBetween: 50,
          },
      }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[FreeMode,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={a} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={b} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={c} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={d} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={e} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={f} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={g} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={h} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={i} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={j} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={k} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={l} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={m} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={n} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={o} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={p} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={q} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={r} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={s} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={t} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={u} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={v} alt="" /></div></SwiperSlide>
        <SwiperSlide><div style={{display:"flex",alignItems:"center",justifyContent:"center", textAlign:"center",height:"100px"}}><img src={w} alt="" /></div></SwiperSlide>
      </Swiper>
  </div>
</section>

<section
  className="fun-fact-section"
  style={{ backgroundImage: "url(images/background/1.jpg)" }}
>
  <div className="auto-container">
    <div className="row clearfix">
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <GrUserWorker />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Total experts</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <GiHelicopter />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Service Done</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <HiOutlineEmojiHappy />

          </div>
          <span className="count-text" data-speed={2000} data-stop={1226}>
            1226
          </span>
          <div className="counter-title">
            <h5>Happy Client</h5>
          </div>
        </div>
      </div>
      {/* Count box */}
      <div className="count-box col-lg-3 col-md-6 col-sm-12 counted">
        <div className="inner-box">
          <div className="icon-box">
          <FaTools />
          </div>
          <span className="count-text" data-speed={2000} data-stop={1035}>
            1035
          </span>
          <div className="counter-title">
            <h5>Total Service</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="video-and-faqs">
  <div className="auto-container">
    <div className="row clearfix">
      {/* Video Column */}
      <div className="video-column col-lg-6 col-md-12 col-sm-12">
       
          
            <img style={{height:"100%"}} src="https://assets.verticalmag.com/wp-content/uploads/2018/08/166-16-103-1024x682.jpg" alt="" />
           
          
       
      </div>
      {/* Faq Column */}
      <div className="faq-column col-lg-6 col-md-12 col-sm-12">
        <div className="inner-column">
          <div className="sec-title ">
            <h2>Some Client FAQ’s</h2>
            <div className="separator">
              <span className='icon'> 
              <LiaHelicopterSolid />
              </span>
          
            </div>
          </div>
          {/*Accordion Box*/}
          <ul className="accordion-box">
      {items.map((item, index) => (
        <li key={index} className={`accordion block ${item.isActive ? 'active-block' : ''}`}>
          <div className={`acc-btn ${item.isActive ? 'active' : ''}`} onClick={() => handleItemClick(index)}>
          {item.isActive ? <FaMinus />: <FiPlus />}
            {item.title}
          </div>
          <div className={`acc-content ${item.isActive ? 'current' : ''}`}>
            <div className="content">
              <p>{item.content}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
          {/*End Accordion Box*/}
        </div>
      </div>
    </div>
  </div>
</section>

<section
  className="testimonial-section"
  style={{ backgroundImage: "url(images/background/3.jpg)" }}
>
  <div className="auto-container">
    <div className="sec-title text-center">
      <h2>What Client Says</h2>
      <div className="separator">
      <LiaHelicopterSolid />
      </div>
    </div>
    {/* Testimonial Block */}
    <div className="testimonial-carousel owl-carousel owl-theme owl-rtl owl-loaded owl-drag">
      {/* Testimonial Block */}
      {/* Testimonial Block */}
      <div className="owl-stage-outer">
        <div
          className="owl-stage"
         
        >
            <Swiper
        slidesPerView={2}
        spaceBetween={30}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
          640: {
              slidesPerView: 1,
              spaceBetween: 20,
          },
          768: {
              slidesPerView: 2,
              spaceBetween: 40,
          },
          1024: {
              slidesPerView: 2,
              spaceBetween: 50,
          },
      }}
        className="mySwiper"
      >
        <SwiperSlide> <div
            className="owl-item cloned"
            style={{ width: 570, marginLeft: 30 }}
          >
            <div className="testimonial-block even">
              <figure className="thumb">
                <img src="https://expert-themes.com/html/motor-expert/images/resource/thumb-1.jpg" alt="" />
              </figure>
              <p>
                “We know that sometimes it’s difficult to get to the phone if
                you are in the middle of something and you don’t want to miss.”
              </p>
              <div className="name">
                DAVID MATIN / <span className="designation">Manager</span>
              </div>
              <span className="icon " >
              <RiDoubleQuotesL />
                </span>
            </div>
          </div></SwiperSlide>
          <SwiperSlide> <div
            className="owl-item cloned"
            style={{ width: 570, marginLeft: 30 }}
          >
            <div className="testimonial-block even">
              <figure className="thumb">
                <img src="https://expert-themes.com/html/motor-expert/images/resource/thumb-1.jpg" alt="" />
              </figure>
              <p>
                “We know that sometimes it’s difficult to get to the phone if
                you are in the middle of something and you don’t want to miss.”
              </p>
              <div className="name">
                DAVID MATIN / <span className="designation">Manager</span>
              </div>
              <span className="icon " >
              <RiDoubleQuotesL />
                </span>
            </div>
          </div></SwiperSlide>
          <SwiperSlide> <div
            className="owl-item cloned"
            style={{ width: 570, marginLeft: 30 }}
          >
            <div className="testimonial-block even">
              <figure className="thumb">
                <img src="https://expert-themes.com/html/motor-expert/images/resource/thumb-1.jpg" alt="" />
              </figure>
              <p>
                “We know that sometimes it’s difficult to get to the phone if
                you are in the middle of something and you don’t want to miss.”
              </p>
              <div className="name">
                DAVID MATIN / <span className="designation">Manager</span>
              </div>
              <span className="icon " >
              <RiDoubleQuotesL />
                </span>
            </div>
          </div></SwiperSlide>
          <SwiperSlide> <div
            className="owl-item cloned"
            style={{ width: 570, marginLeft: 30 }}
          >
            <div className="testimonial-block even">
              <figure className="thumb">
                <img src="https://expert-themes.com/html/motor-expert/images/resource/thumb-1.jpg" alt="" />
              </figure>
              <p>
                “We know that sometimes it’s difficult to get to the phone if
                you are in the middle of something and you don’t want to miss.”
              </p>
              <div className="name">
                DAVID MATIN / <span className="designation">Manager</span>
              </div>
              <span className="icon " >
              <RiDoubleQuotesL />
                </span>
            </div>
          </div></SwiperSlide>
       
      </Swiper>
         
         
        
        </div>
      </div>
   
    </div>
  </div>
</section>


    </div>
  )
}

export default Home

import React,{useState} from 'react'
import Servicedata from '../components/Servicedata';
import { useLocation } from 'react-router-dom';
const Servicedetil = () => {
  const search = useLocation();
  const Path = search.pathname;

  const [edata, setEdata] = useState(Servicedata);
  // const [Fdata,setFdata] = useState([])

  const EventFdata = edata.find((e) => e.slug === Path);
  // setFdata(EventFdata);

  console.log("EventFdata",EventFdata);
  return (
    <div>
      <section
  className="page-title"
  style={{ backgroundImage: "url(https://img.freepik.com/free-photo/engineer-working-with-airplane_1098-12545.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1705104000&semt=ais)" }}
>
  <div className="auto-container">
    <div className="inner-container clearfix">
      <h1>service detail</h1>
      <ul className="bread-crumb clearfix">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li>{EventFdata.service}</li>
      </ul>
    </div>
  </div>
</section>
<div className="service-detail-section">
  <div className="auto-container">
    <div className="row clearfix">
      {/*Content Side*/}
      <div className="content-side pull-right col-lg-9 col-md-12 col-sm-12">
        <div className="service-detail">
          <div className="service-detail-slider owl-carousel owl-theme owl-rtl owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                 
                }}
              >
                <div
                  className="owl-item cloned"
                  style={{ width: 870, marginLeft: 30 }}
                >
                  <div className="slide-item">
                    <div className="image-box">
                      <figure>
                        <img src={EventFdata.img} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              
              
              </div>
            </div>
            <div className="owl-nav">
              <div className="owl-prev">
                <span className="fa fa-angle-left" />
              </div>
              <div className="owl-next">
                <span className="fa fa-angle-right" />
              </div>
            </div>
           
          </div>
          <div className="lower-content">
            <h2>{EventFdata.service}</h2>
            <p>
             {EventFdata.descrption}
            </p>
            <h5>{EventFdata.title1}</h5>
            <p>
            {EventFdata.description1}
            </p>
            <h5>{EventFdata.title2}</h5>
            <p>
            {EventFdata.description2}
            </p>
            <h5>{EventFdata.title3}</h5>
            <p>
            {EventFdata.description3}
            </p>
            <h5>{EventFdata.title4}</h5>
            <p>
            {EventFdata.description4}
            </p>
            <h5>{EventFdata.title5}</h5>
            <p>
            {EventFdata.description5}
            </p>
            <div className="row">
              <div className="col-6">
                <p>{EventFdata.li1}</p>
                <p>{EventFdata.li2}</p>
                <p>{EventFdata.li3}</p>
                <p>{EventFdata.li4}</p>
                <p>{EventFdata.li5}</p>
                <p>{EventFdata.li6}</p>
              </div>
              <div className="col-6">
              <p>{EventFdata.li7}</p>
                <p>{EventFdata.li8}</p>
                <p>{EventFdata.li9}</p>
                <p>{EventFdata.li10}</p>
                <p>{EventFdata.li11}</p>
                <p>{EventFdata.li12}</p>
              </div>
            </div>
           
            
          </div>
        </div>
        {/* Service Detail */}
        {/* Pricing Feature */}
        
        {/* End pricing */}
      </div>
      {/*Sidebar Side*/}
      <div className="sidebar-side col-lg-3 col-md-4 col-sm-12">
        <aside className="sidebar services-sidebar">
          {/* Services Cat List */}
          <div className="sidebar-widget categories">
            <ul className="service-cat-list">
            {Servicedata.map((ele) => {
                          const { service, slug } = ele;
                          return (
              <li>
                <a href={slug}>{service}</a>
              </li>
               );
              })}
            </ul>
          </div>
          {/* Brochure */}
     
        </aside>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default Servicedetil

const Servicedata =[
    {
        slug:"/NDTSHOP",
        service:"NDT SHOP",
        img:"https://ndtblog-us.fujifilm.com/wp-content/uploads/2021/08/01-What-Is-Non-Destructive-Testing-min.jpg",
        descrption:"The NDT shop is DGCA approved. We are having NDT level III engineer on board for providing the best possible quality solution of components and Aircraft. NDT methods may rely upon use of electromagnetic radiation, sound, and inherent properties of materials to examine samples.",
        title1:"Magnetic Particle Inspection (Magnaflux)",
        description1:"Magnetic particle inspection, sometimes called MT, MPI, or mag particle, is a non-destructive test method used to check for surface and sub-surface discontinuities and defects. The process puts a magnetic field into the part and the piece is magnetized by direct or indirect magnetization. Because this method relies on a magnetic field to produce results, it works best on ferrous materials, like nickel, iron, cobalt, and some alloys. Components like billets, bars, castings, forgings, fasteners, weldments and extrusions are also commonly tested using magnetic particle inspection.",
        title2:"Florescent Penetrant Inspection (Magnaflux)",
        description2:"An industry standard for decades, liquid penetrant testing—also known as zyglo testing, PT services, fluorescent testing, or dye penetrant testing—remains one of the most reliable, efficient, and cost-effective non-destructive methods for detecting surface flaws in non-porous metals.Liquid penetrant inspection is a valuable tool for evaluating the surfaces of welds, castings, and other components that cannot be destroyed. In addition to checking for cracks, it can also be used to determine other surface characteristics, such as porosity. Liquid penetrant inspection is performed by examining defects made visible by a penetrant fluid. After applying a liquid dye and allowing for proper dwell time, the part is cleaned and a developing powder is applied. The developer pulls up liquid that has seeped into cracks or pores, resulting in visible marks that identify defects. An inspector can then provide a report with the type, number and size of defects on a component. ",
        title3:"Eddy Current Inspection (Olympus)",
        description3:"Eddy Current testing is an efficient and precise method. This type of non-destructive examination is particularly helpful to evaluate heat exchangers and aircraft parts and components. In liquid penetrant non-destructive testing, dye flows into a sample's cracks and defects and makes them visible, either to the naked eye or under fluorescent light. In much the same way, Element Materials Technology scientists employing Eddy Current inspection direct electrical currents to flow over a sample.A flow of periodic electrical currents in conductors called probes creates varying electromagnetic fields. These probes are placed in close proximity to the surfaces of the test materials, and can detect effects of processing and shaping operations during production, as well as corrosion damage or cracking for most nonmagnetic metals and alloys. Eddy current techniques are commonly used for the non-destructive examination and condition monitoring of a large variety of metallic structures, including heat exchanger tubes, aircraft fuselage, and aircraft structural components. ",
        title4:"Ultra Sonic Inspection",
        description4:"Ultrasonic inspection (UT) is a non-destructive test method that utilizes sound waves to detect cracks and defects in parts and materials. It can also be used to determine a material’s thickness, such as measuring the wall thickness of a avition. Because of the portability of the equipment and variety of methods available, ultrasonic testing allows for inspection of parts that are large, irregularly shaped, or unable to be transported. Ultrasonic inspection relies on electronic transducers that transmit high-frequency sound waves to a material. These sound waves bounce back crystal-clear images, which reveal key characteristics about a material’s properties. The images created by ultrasonic testing can indicate cracks, weld grooves and fractures, as well as indicate material thickness and outline moving components. Because this method has higher power potential than other non-destructive test types, ultrasonic inspection can produce images that are more clearly defined than other methods, and indicate characteristics deeper than surface level. Depending upon the test requirements, parts can be tested by immersion (in-lab testing) or contact (portable/in-air) inspection",
        title5:"Radiographic inspection",
        description5:"Radiography is one of the most commonly used Non-Destructive testing methods used in the aviation industry. Avitr Aviation Radiography Services uses x-ray technology to inspect aircraft and aircraft components for hidden defects, alongside other commonly applied methods of NDT. Radiography non-destructive testing (also known as RT) involves passing radiation through the material being tested and onto film. We use radiography to test and inspect composite parts for integrity, water intrusion and other defects. It’s used on metallic parts such as welds or castings to reveal corrosion, loose fittings, rivets and other defects. Radiography in aviation can be used to verify damage on suspect areas without the need to remove the component being inspected. In many instances, NDT Technicians can employ x-ray technology to determine if structural damage exists, which can save the customer man-hours and cost.",
    },
    {
        slug:"/WHEEL-AND-BRAKE-SHOP",
        service:"WHEEL AND BRAKE SHOP",
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRxlyrX7H-WiwHia7IyiEPvhn-ZRJjKya__5lwea-oI44cskQkFmXh1KCe&s=10",
        descrption:"Avitr Operations and Maintenance LLP In India is DGCA approved MRO. A full- fledged facility with latest automated equipment’s, and it offers comprehensive MRO C14 category (Wheels & Brakes) services to airline customers worldwide through its facility in Mumbai, India.",
        title1:"Type Of Aircraft Maintenance Including Servicing and Overhaul of Wheels & Brakes",
        li1:"Beech Craft C90/200/300/400/400A",
        li2:"Beech Craft Premier",
        li3:"Global Express",
        li4:"Cessna Series",
        li5:"Embraer-135/145/170/190/500/Phencm-100",
        li6:"Gulf Stream G-150/G-V",
        li7:"Learjet 60",
        li8:"Piaggio GP180",
        li9:"CRJ 200",
        li10:"Challenger 604",
        li11:"Dornier 228",
        li12:"Piltus PC-12",

    },
    {
        slug:"/CALIBRATIONSHOP",
        service:"CALIBRATION SHOP",
        img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqAnvIu1nR8f7MdqPnjVDR-lJSfXy7Pldb217P_sg7eE8oMZyh1nTnm_lR&s=10",
        descrption:"Avitr Operations and Maintenance LLP In India is ISO/IEC 17025 accredited calibration laboratory by NABL. A full- fledged facility with latest automated equipment’s, and it offers comprehensive Calibration services to airline customers worldwide through its facility in Mumbai, India. We are doing all type of instruments calibration and as per client need, we can do calibration in your laboratory or at site. Customer can submit the instruments at any of our branches. With shifts working, we are able to satisfy customer emergency situations, as well as, provide quick turnaround calibration services on a consistent basis. Our approach of being there to satisfy your most demanding time critical calibration or repair needs We provide calibration services to our customers in either our own environmentally controlled multi calibration laboratory facility or at their sites. The customers are using AOML calibration laboratory for instrument calibration of pressure & dimension.",
       

    }
]

export default Servicedata